.home-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  flex: 1;
}

.carousel-container {
  flex: 1;
  border: 2px solid #cc90cc;
  border-radius: 20px;
  box-shadow: 0 0 15px #cc90cc;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  position: relative;
  width: 100%;
  max-width: 830px; /* Maximum width */
  max-height: 675px; /* Maximum height */
  margin: 0 auto;
  padding: 0;
}

.carousel-container:hover {
  transform: scale(1.05);
}

.content-container {
  flex: 1;
  padding: 16px;
  border: 2px solid #ed93ed;
  border-radius: 10px;
  box-shadow: 0 0 15px #ed93ed;
  background-color: transparent;
}

.welcome-header {
  color: #ffffff;
  text-align: center;
  font-size: 2em; /* Default font size */
  margin-bottom: 20px;
}

.panel-header {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.panel-header:hover {
  color: #e189e1 !important;
}

.panel-content {
  color: #ffffff;
}

.ant-collapse-content-box {
  background-color: #333;
  border-top: 1px solid #e189e1;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
  color: #e189e1 !important;
}

@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
  }

  .carousel-container {
    width: 100%;
    order: 1;
    margin-bottom: 16px;
    height: 400px; /* Adjusted height for mobile */
    max-width: none;
    max-height: none;
  }

  .content-container {
    width: 100%;
    order: 2;
    margin-left: 0;
  }

  .welcome-header {
    font-size: 1.5em; /* Adjusted font size for mobile */
  }
}
