.contact-form-container {
    width: 100%;
    max-width: 600px;
    margin: 0;
    padding: 20px;
    background-color: transparent;
    border: 1px solid #ed93ed;
    border-radius: 10px;
    box-shadow: 0 0 15px #ed93ed;
    color: #ffffff;
    overflow: auto;
    transition: transform 0.3s ease;
  }
  
  .contact-form-container:hover {
    transform: perspective(1000px) rotateY(10deg);
  }
  
  .contact-form-title {
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 0 0 10px #ed93ed;
    border-bottom: 1px solid #ed93ed;
    padding-bottom: 10px;
  }
  
  .contact-form {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 20px;
  }
  
  .ant-form-item-label > label {
    color: #ed93ed;
  }
  
  .ant-input,
  .ant-textarea {
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    border: 1px solid #ed93ed;
  }
  
  .ant-input::placeholder,
  .ant-textarea::placeholder {
    color: #e189e1;
  }
  
  .ant-btn-primary {
    background: #ed93ed;
    border-color: #ed93ed;
    transition: background-color 0.3s ease, transform 0.3s ease;
    transform: scale(1);
  }
  
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background: #e189e1;
    border-color: #e189e1;
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .contact-form-container {
      margin: 0 auto; /* Center align for mobile view */
    }
  }
  