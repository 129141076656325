body, html {
  height: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
}

#root {
  background-color: #bd1818; /* Dark background for a cyberpunk feel */
  background-image: radial-gradient(circle, #333333, #1a1a1a); /* Radial gradient for cyberpunk look */
  background-size: cover; /* Ensure the gradient covers the entire background */
  background-attachment: fixed; /* Fix the background to prevent it from moving with the scroll */
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.ant-layout {
  background: transparent;
}

.fullscreen-layout {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: transparent;
}

.sidebar-robot {
  background-color: #1a1a1a;
  border-right: 2px solid #ed93ed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 15px #ed93ed;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1;
  overflow-y: auto;
}

.sidebar-robot .logo {
  font-size: 19px;
  color: #b94bf7;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  text-shadow: 0 0 5px #b94bf7, 0 0 10px #b94bf7, 0 0 15px #b94bf7, 0 0 20px #b94bf7;
}

.ant-layout-sider-collapsed .logo {
  font-size: 0;
}

.ant-layout-sider-collapsed .logo::before {
  content: "AH";
  font-size: 24px;
  display: inline-block;
  text-shadow: 0 0 5px #b94bf7, 0 0 10px #b94bf7, 0 0 15px #b94bf7, 0 0 20px #b94bf7;
}

.sidebar-robot .menu-item {
  color: #ffffff;
  font-size: 18px;
  margin: 10px 0;
  transition: transform 0.3s ease, color 0.3s ease;
}

.sidebar-robot .menu-item:hover {
  transform: scale(1.1);
  color: #e189e1;
}

.content-layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  height: 100vh;
  background: transparent;
}

.content-container {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: transparent;
}

.footer {
  background-color: #1a1a1a;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  box-shadow: 0 -2px 10px #ed93ed;
  margin-top: auto;
}
