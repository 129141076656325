.carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    perspective: 1000px;
    border-radius: 20px;
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
    transition: transform 1s ease-in-out, filter 0.3s ease-in-out;
    transform-origin: center;
    backface-visibility: hidden;
    border-radius: 20px;
  }
  
  .carousel-slide:hover .carousel-image {
    transform: scale(1.1) rotateY(15deg);
    filter: brightness(1.2);
  }
  
  .carousel-container {
    flex: 1;
    border: 2px solid #cc90cc;
    border-radius: 20px;
    box-shadow: 0 0 15px #cc90cc;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    position: relative;
    width: 100%;
    max-width: 830px; /* Maximum width */
    max-height: 675px; /* Maximum height */
    margin: 0 auto; /* Center the carousel */
    padding: 0;
  }
  
  .carousel-container:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 1024px) {
    .carousel-container {
      max-width: 800px;
      max-height: 650px;
    }
  }
  
  @media (max-width: 768px) {
    .carousel-container {
      width: 100%;
      order: 1;
      margin-bottom: 16px;
      height: 400px; /* Adjusted height for mobile */
      max-width: none;
      max-height: none;
    }
  }
  