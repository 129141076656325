.services-container {
    width: 100%;
    max-width: 1000px;
    margin: 10px 20px 20px 0;
    padding: 0;
    background-color: transparent;
    color: #ffffff;
    perspective: 1200px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .services-title {
    color: #ffffff;
    text-align: center;
    font-size: 3em;
    margin-bottom: 30px;
    text-shadow: 0 0 15px #e7a1ef;
    padding: 10px;
    border-bottom: 1px solid #e7a1ef;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    flex: 1;
  }
  
  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 300px;
    perspective: 1000px;
    position: relative;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ed93ed;
  }
  
  .flip-card-front {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .flip-card-back {
    color: #ffffff;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .services-note {
    margin-top: 30px;
    text-align: center;
    font-style: italic;
    color: #e7a1ef;
  }
  
  .phone-icon-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .phone-icon {
    color: #e7a1ef;
    font-size: 24px;
  }
  
  @media (max-width: 1024px) {
    .services-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .card-human-resources {
      grid-column: 1 / span 2;
      grid-row: 3;
    }
  }
  
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  
    .card-haircuts {
      order: 1;
    }
  
    .card-coloring {
      order: 2;
    }
  
    .card-treatments {
      order: 3;
    }
  
    .card-special-treatments {
      order: 4;
    }
  
    .card-human-resources {
      order: 5;
    }
  
    .services-container {
      max-width: 100%;
      padding: 20px;
      margin: 0 auto;
    }
  
    .flip-card {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
  
    .flip-card-inner {
      padding: 10px;
      min-height: 300px;
    }
  
    .flip-card-front,
    .flip-card-back {
      border-radius: 10px;
      padding: 10px;
    }
  }
  